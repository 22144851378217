import React, { Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Loader from './component/loader';

// All Headers
const Header1 = React.lazy(() => import('./layouts/header/Header1'));


// All Footers
const FooterStyle01 = React.lazy(() =>
  import('./layouts/footer/footer-style-01')
);


const BackTop = React.lazy(() => import('./component/back-top'));

// Home
const HomeIndex = React.lazy(() => import('./pages/home1/index'));


const Blog = React.lazy(() => import('./pages/blog/Blog'));
const BlogGrid = React.lazy(() => import('./pages/blog/blog-grid'));
const BlogSingle = React.lazy(() => import('./pages/blog/blog-details'));

const ContactUs = React.lazy(() => import('./pages/contact/contact'));

// Pages
const AboutUs = React.lazy(() => import('./pages/about-us'));
const WhyChooseUs = React.lazy(() => import('./pages/why-choose-us'));
const Team = React.lazy(() => import('./pages/team/team'));
const TeamSingle = React.lazy(() => import('./pages/team/team-single'));
const ClientReviews = React.lazy(() => import('./pages/client-reviews'));
const OurWork = React.lazy(() => import('./pages/our-work'));
const AwardsAndRecognitions = React.lazy(() => import('./pages/awards-and-recognitions'));
const Careers = React.lazy(() => import('./pages/careers'));

// services

const WebDevelopment = React.lazy(() => import('./pages/services/web-development'));
const AppDevelopment = React.lazy(() => import('./pages/services/app-development'));
const WebDesign = React.lazy(() => import('./pages/services/web-design'));
const DigitalMarketing = React.lazy(() => import('./pages/services/digital-marketing'));
const WebsiteMaintenance = React.lazy(() => import('./pages/services/website-maintenance'));
const GoogleCertifiedExperts = React.lazy(() => import('./pages/services/google-certified-experts'));

const WebDevelopmentDetail = React.lazy(() =>
  import('./pages/services-details/web-development-details')
);
const AppDevelopmentDetail = React.lazy(() =>
  import('./pages/services-details/app-development-details')
);
const WebDesignDetail = React.lazy(() =>
  import('./pages/services-details/web-design-details')
);
const DigitalMarketingDetail = React.lazy(() =>
  import('./pages/services-details/digital-marketing-details')
);
const WebsiteMaintenanceDetail = React.lazy(() =>
  import('./pages/services-details/website-maintenance-details')
);
const GoogleCertifiedExpertsDetail = React.lazy(() =>
  import('./pages/services-details/google-certified-experts-details')
);



function App() {
  const location = useLocation();
  const hideFooter = location.pathname !== '/coming-soon';

  return (
    <Suspense fallback={<Loader />}>
      <div className="main-wrapper">
        <Routes>
          <Route path="/" element={<Header1 />}>
            {/* Home */}
            <Route index path="/" element={<HomeIndex />} />
         


            {/* About Us */}
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/why-choose-us" element={<WhyChooseUs />} />
            <Route path="/team" element={<Team />} />
            <Route path="/team-details/:id" element={<TeamSingle />} />
            <Route path="/client-reviews" element={<ClientReviews />} />
            <Route path="/our-work" element={<OurWork />} />
            <Route path="/awards-and-recognitions" element={<AwardsAndRecognitions />} />
            <Route path="/careers" element={<Careers />} />

            {/* Service */}
            {/* <Route path="/service" element={<Service />} /> */}
            <Route path="/web-development" element={<WebDevelopment />} />
            <Route path="/app-development" element={<AppDevelopment />} />
            <Route path="/web-design" element={<WebDesign />} />
            <Route path="/digital-marketing" element={<DigitalMarketing />} />
            <Route path="/website-maintenance" element={<WebsiteMaintenance />} />
            <Route path="/google-certified-experts" element={<GoogleCertifiedExperts />} />
            <Route path="/web-development-details/:id" element={<WebDevelopmentDetail />} />
            <Route path="/app-development-details/:id" element={<AppDevelopmentDetail />} />
            <Route path="/web-design-details/:id" element={<WebDesignDetail />} />
            <Route path="/digital-marketing-details/:id" element={<DigitalMarketingDetail />} />
            <Route path="/website-maintenance-details/:id" element={<WebsiteMaintenanceDetail />} />
            <Route path="/google-certified-experts-details/:id" element={<GoogleCertifiedExpertsDetail />} />

            {/* blog */}
            
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog-grid" element={<BlogGrid />} />
            <Route path="/blog-details/:id" element={<BlogSingle />} />



            {/* COntact Us */}

            <Route path="/contact" element={<ContactUs />} />
            

          </Route>


         
        </Routes>

        {/* All Footer */}
        {hideFooter && (
          <Routes>
            <Route path="/*" element={<FooterStyle01 />} />{' '}
            {/* Footer Default */}
            
          </Routes>
        )}

        {/* Back to Top */}
        <BackTop />
      </div>
    </Suspense>
  );
}

export default App;
